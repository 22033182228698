import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Layout.jsx";
import { graphql } from 'gatsby';
import TipsList from '../../components/TipsList';
export const pageQuery = graphql`
  query {
    allMdx(
      filter: { slug: { regex: "/mechanic/tutorials//" } }
      sort: { fields: [frontmatter___title], order: ASC }
    ) {
      edges {
        node {
          id
          slug
          excerpt(pruneLength: 250)
          frontmatter {
            title
            author
            description
            tags
          }
        }
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Heading = makeShortcode("Heading");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Heading frontmatter={props.pageContext.frontmatter} mdxType="Heading" />
    <p>{props.pageContext.frontmatter.description}</p>
    <TipsList tips={props.data.allMdx.edges} mdxType="TipsList" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      